<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="Environment"
        >
          <InputWrapper
            type="toggle"
            :label="'Show Ground Plane'"
            :value="getSetting('env.ground.enabled')"
            @change="onChange('env.ground.enabled', $event)"
          />
        </BlockGroup>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '../GuiSettingMixin'

export default {
  name: 'SettingsEnvBlock',

  mixins: [mixin],

  data () {
    return {
      collapsed: false
    }
  }
}
</script>
