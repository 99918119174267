<template>
  <component
    :is="inputType"
    v-if="row"
    :key="key"
    :row="row"
    :value-dynamic="valueDynamic"
    @change="$emit('change', $event)"
  />
</template>
<script>
const UnknownInput = () =>
  import('@components/editor/module/inputs/UnknownInput')
const TextInput = () => import('@components/editor/module/inputs/TextInput')
const NumberInput = () => import('@components/editor/module/inputs/NumberInput')
const FileInput = () => import('@components/editor/module/inputs/FileInput')
const TextureInput = () =>
  import('@components/editor/module/inputs/TextureInput')
const TextureAssetInput = () =>
  import('@components/editor/module/inputs/TextureAssetInput')
const MapInput = () => import('@components/editor/module/inputs/MapInput')
const InfoInput = () => import('@components/editor/module/inputs/InfoInput')
const ToggleInput = () => import('@components/editor/module/inputs/ToggleInput')
const CheckboxInput = () =>
  import('@components/editor/module/inputs/CheckboxInput')
const RadioInput = () => import('@components/editor/module/inputs/RadioInput')
const ButtonInput = () => import('@components/editor/module/inputs/ButtonInput')
const PositionInput = () =>
  import('@components/editor/module/inputs/PositionInput')
const SliderInput = () => import('@components/editor/module/inputs/SliderInput')
const MappingInput = () =>
  import('@components/editor/module/inputs/MappingInput')
const SelectInput = () => import('@components/editor/module/inputs/SelectInput')
const ColorInput = () => import('@components/editor/module/inputs/ColorInput')

export default {
  name: 'InputWrapper',

  components: {
    UnknownInput,
    FileInput,
    TextureInput,
    TextureAssetInput,
    MapInput,
    TextInput,
    NumberInput,
    InfoInput,
    ToggleInput,
    CheckboxInput,
    RadioInput,
    ButtonInput,
    PositionInput,
    SliderInput,
    MappingInput,
    ColorInput,
    SelectInput
  },

  props: {
    type: {
      required: true,
      type: String
    },
    label: {
      required: false,
      type: String
    },
    value: {
      required: false
    },
    values: {
      required: false,
      type: Object
    },
    extra: {
      required: false,
      type: String
    },
    units: {
      required: false,
      type: String
    },
    short: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    step: {
      type: Number,
      required: false
    },
    lockable: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      required: false
    },
    inputClass: {
      type: String,
      required: false
    },
    nested: {
      type: Boolean,
      required: false
    },
    options: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      row: null,
      key: null,
      valueDynamic: null
    }
  },

  computed: {
    labelComputed() {
      if (this.label !== null) return this.label
      return null
    },

    extraComputed() {
      if (this.extra !== null) return this.extra
      return null
    },

    inputType() {
      switch (this.type) {
        case 'text': {
          return 'TextInput'
        }
        case 'number': {
          return 'NumberInput'
        }
        case 'info': {
          return 'InfoInput'
        }
        case 'toggle': {
          return 'ToggleInput'
        }
        case 'checkbox': {
          return 'CheckboxInput'
        }
        case 'radio': {
          return 'RadioInput'
        }
        case 'button': {
          return 'ButtonInput'
        }
        case 'position': {
          return 'PositionInput'
        }
        case 'slider': {
          return 'SliderInput'
        }
        case 'color': {
          return 'ColorInput'
        }
        case 'file': {
          return 'FileInput'
        }
        case 'texture': {
          return 'TextureInput'
        }
        case 'texture-asset': {
          return 'TextureAssetInput'
        }
        case 'map': {
          return 'MapInput'
        }
        case 'mapping': {
          return 'MappingInput'
        }
        case 'select': {
          return 'SelectInput'
        }
      }

      return 'UnknownInput'
    }
  },

  watch: {
    value(newVal) {
      this.setUpStates()
    },
    values(newVal) {
      // Watches for changes upstream.
      this.setUpStates()

      // Yes, this is a weird way to do it, but
      // this enables reactivity on the value, while allowing for flexibility
      // on the general props and setup
      // There is a watcher in `inputMixin` that listens for changes on this value
      // and updates the model internally

      // Why have we done it this way?
      // There are 2 cases this changes -
      // 1. the value is updated in-component, ie. a user updates a number input directly
      // 2. the value is updated out-of-component, ie. a module is moved, and the root value changes
      // in that case we want the value to update, but the component to state alive for focus

      // Disabled @todo
      // This ends up causing more problems than it solves.
      // We end up in an infinite loop of cycling value updates
      // which kills everything.
      // Rethink this.
      // this.valueDynamic = newVal
    }
  },

  mounted() {
    this.key = Date.now()
    this.setUpStates()
  },

  methods: {
    setUpStates() {
      this.row = {}
      this.row.label = this.labelComputed
      this.row.extra = this.extraComputed
      this.row.model = this.valueComputed

      if (this.value !== null) {
        this.row.value = this.value
        this.valueDynamic = this.value
      }

      if (this.values !== null) this.row.values = this.values
      if (this.short !== null) this.row.short = this.short
      if (this.min !== null) this.row.min = this.min
      if (this.max !== null) this.row.max = this.max
      if (this.step !== null) this.row.step = this.step
      if (this.simple !== null) this.row.simple = this.simple
      if (this.lockable !== null) this.row.lockable = this.lockable
      if (this.inputClass !== null) this.row.inputClass = this.inputClass
      if (this.nested !== null) this.row.nested = this.nested
      if (this.options !== null) this.row.options = this.options
      if (this.units !== null) this.row.units = this.units
    }
  }
}
</script>
