<template>
  <!-- <div class="p-1 text-xs text-yellow-400 bg-gray-900 border-b border-gray-700"> -->

  <div class="px-1 py-2 text-xs font-bold text-gray-100 bg-gray-800 border-b border-gray-700">
    <div class="mx-0 select-none">
      <div class="flex">
        <div
          class="flex-auto"
          :class="{ 'hover:underline cursor-pointer' : isCollapsable }"
          @click.prevent="isCollapsable ? $emit('toggle-block-collapse') : ''"
        >
          <span
            v-if="isCollapsable"
            class="pr-0.5"
          >
            <fa-icon
              v-if="collapsed"
              icon="caret-right"
              class="fa-fw"
              size="lg"
            />
            <fa-icon
              v-else
              icon="caret-down"
              class="fa-fw"
              size="lg"
            />
          </span>

          <slot>{{ label }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BlockHeader',

  props: {
    label: {
      type: String,
      default: ''
    },

    collapsed: {
      type: Boolean,
      default: false
    },

    collapsable: {
      type: Boolean,
      default: false
    },

    bgClass: {
      type: String,
      default: 'bg-gray-900 text-yellow-400'
    }
  },

  computed: {
    isCollapsable () {
      // return false
      return this.collapsable === true
    }
  }

}
</script>
