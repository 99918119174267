import InputWrapper from '@components/editor/module/inputs/InputWrapper'
import BlockHeader from '@components/editor/scene/blocks/BlockHeader'
import BlockGroup from '@components/editor/scene/blocks/BlockGroup'

export default {

  components: {
    InputWrapper,
    BlockHeader,
    BlockGroup
  },

  props: {
    gui: {
      required: true,
      type: Object
    }
  },

  methods: {

    getSetting (key) {
      return this.gui.getSetting(key)
    },

    onChange (key, value) {
      this.gui.setSetting(key, value)
      this.$emit('settings-changed')
    }

  }

}
